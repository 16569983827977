.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.affirm-modal-trigger {
  display:none;
}



/* CustomStyles.css */
@keyframes spinning82341 {
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  position: fixed;
  left: 20px;
  bottom:130px;
  background-image: linear-gradient(rgb(186, 66, 255) 35%, rgb(0, 225, 255));
  box-shadow: 0 -5px 20px rgb(186, 66, 255), 0 5px 20px rgb(0, 225, 255);
  animation: spinning82341 1.7s linear infinite;
}

.spinner1 {
  filter: blur(10px);
}

textarea:focus {
  outline: none;
  box-shadow: none;
}

